import { motion, useTransform, useScroll } from "framer-motion";
import { useRef } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FiArrowUpRight } from "react-icons/fi";

function WorkScroll() {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });
  const x = useTransform(scrollYProgress, [0, 2], ["1%", "-175%"]);
  const { t } = useTranslation();

  const cards = [
    {
      url: "/project_1.webp",
      category: ["Web dev", "SEO", "Design"],
      title: "Art & design d'interieur",
      desc: t("work.p1"),
      link: "https://artetdesigndinterieur.fr/",
      id: 1,
    },
    {
      url: "/project_2.webp",
      category: ["Design", "UI/UX"],
      title: "Travel App",
      desc: t("work.p2"),
      id: 2,
    },
    {
      url: "/project_3.webp",
      category: ["Web dev", "Design"],
      title: "Artha | Transit Manager",
      desc: t("work.p3"),
      id: 3,
    },
    {
      url: "/project_4.webp",
      category: ["Design", "UI/UX"],
      desc: t("work.p4"),
      link: "https://the-resto-lopez.netlify.app/",
      title: "THE RESTO",
      id: 4,
    },
    {
      url: "/project_5.webp",
      category: ["Game", "Design", "App", "Desktop"],
      desc: t("work.p5"),
      link: "https://mythic-masters.tokyowebbyguillaume.com/",
      title: "Mythic Masters",
      id: 5,
    },
    {
      url: "/project_6.webp",
      category: ["Design", "Localization", "UI/UX", "English"],
      desc: t("work.p6"),
      link: "https://www.figma.com/design/lMP7nkWWQlBlbdTaLkJsD8/BeMap-English?node-id=0-1&t=D6O8AjBIfkyPicJ9-1",
      title: "BeMap (English version)",
      id: 5,
    },
    {
      url: "/project_7.webp",
      category: ["Design", "UI/UX", "Web Dev", "Branding"],
      desc: t("work.p7"),
      link: "https://www.figma.com/design/qeJzhaZ67NhtyImVN7qYmE/Gladsome-%7C-Gluten-Free?node-id=0-1&t=BdD172r3zLHHdD4s-1",
      title: "Gladsome | Gluten-Free",
      id: 5,
    },
  ];

  return (
    <section
      ref={targetRef}
      className="ml-[10%] relative h-[300vh] bg-white mx-auto"
    >
      <div className="sticky top-0 flex h-screen items-center overflow-hidden">
        <motion.div style={{ x }} className="flex gap-8">
          {cards.map((card) => {
            return <Card card={card} key={card.id} />;
          })}
        </motion.div>
      </div>
    </section>
  );
}

const Card = ({ card }) => {
  return (
    <div
      key={card.id}
      className=" h-[50vh] w-[50vh] overflow-hidden text-black cursor-pointer transition-transform ease-in-out hover:-translate-y-2 hover:bg-slate-50 shadow"
    >
      <div
        style={{
          backgroundImage: `url(${card.url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="h-[60%] "
      ></div>
      <div className="flex flex-col px-4 gap-y-4 mt-4 font-[Oswald]">
        <div className="flex gap-x-4">
          {card.category.map((cat, index) => (
            <span
              key={index}
              className="border w-[75px] py-1 px-2 text-[#FFA41B] border-[#FFA41B] text-center"
            >
              {cat}
            </span>
          ))}
        </div>
        <div className="flex justify-between items-center">
          <h3 className="text-3xl ">{card.title}</h3>

          {card.link ? (
            <a href={card.link} target="_blank" aria-label={card.title}>
              <FiArrowUpRight className="text-4xl text-[#FFA41B] border border-[#FFA41B] p-2 hover:bg-[#FFA41B] hover:text-white" />
            </a>
          ) : (
            ""
          )}
        </div>
        <p className="text-[#7D7C7C]">{card.desc}</p>
      </div>
    </div>
  );
};

export default WorkScroll;
