import React from 'react';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';

function Form() {
  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_n9z7uv8', 'template_hrpx8t1', e.target, '3TswScplWokx41TDA')
      .then((result) => {
          console.log(result.text);
          alert("Thank you for your message! We will get back to you soon.");
      }, (error) => {
          console.log(error.text);
          alert("Oops! There was a problem submitting your form.");
      });

    e.target.reset();
  };
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit} className='w-full font-[Oswald] font-light text-3xl flex flex-col gap-y-12'>
        <h3 className='text-6xl'>{t("form.title.part1")} <br /> <strong className='font-[Huntsman] text-[#FFA41B]'>{t("form.title.part2")}</strong></h3>

        <h3 className='font-normal'>{t("form.sub1")}</h3>

        <div className='flex flex-col gap-y-4'>
            <p>1. {t("form.question1.question")}</p>
            <select id="website" name="website" className='h-[75px] bg-transparent border-b outline-none placeholder:font-light hover:border-black transition ease-in-out' required>
                <option className='text-black' value="I have one">{t("form.question1.answer1")}</option>
                <option className='text-black' value="I want one">{t("form.question1.answer2")}</option>
            </select>
            <input type="text" name="existing_website" placeholder={t("form.question1.option")} className='h-[75px] bg-transparent border-b outline-none placeholder:font-light hover:border-black transition ease-in-out'/>
        </div>

        <div className='flex flex-col gap-y-4'>
            <p>2. {t("form.question2.question")}</p>
            <select id="project" name="project_type" className='h-[75px] bg-transparent border-b outline-none placeholder:font-light hover:border-black transition ease-in-out' required>
                <option className='text-black' value="Web design">{t("form.question2.answer1")}</option>
                <option className='text-black' value="Development">{t("form.question2.answer2")}</option>
                <option className='text-black' value="SEO">{t("form.question2.answer3")}</option>
            </select>
        </div>

        <div className='flex flex-col gap-y-4'>
            <p>3. {t("form.question3.question")}</p>
            <select id="budget" name="budget" className='h-[75px] bg-transparent border-b outline-none placeholder:font-light hover:border-black transition ease-in-out' required>
                <option className='text-black' value="-50 000￥">{t("form.question3.answer1")}</option>
                <option className='text-black' value="50 000￥ - 100 000￥">{t("form.question3.answer2")}</option>
                <option className='text-black' value="100 000￥ - 200 000￥">{t("form.question3.answer3")}</option>
                <option className='text-black' value="+200 000￥">{t("form.question3.answer4")}</option>
            </select>
        </div>

        <h3 className='font-normal text-4xl'>{t("form.sub2")}</h3>

        <div className='flex flex-col gap-y-4'>
            <p>4. {t("form.question4.question")}</p>
            <div className='flex flex-col gap-y-2'>
                <input type="text" name="first_name" placeholder={t("form.question4.answer1")} className='h-[75px] bg-transparent border-b outline-none placeholder:font-light hover:border-black transition ease-in-out'required/>
                <input type="text" name="last_name" placeholder={t("form.question4.answer2")} className='h-[75px] bg-transparent border-b outline-none placeholder:font-light hover:border-black transition ease-in-out'required/>
            </div>
        </div>

        <div className='flex flex-col gap-y-4'>
            <p>5. {t("form.question5.question")}</p>
            <select id="company" name="company"  className='h-[75px] bg-transparent border-b outline-none placeholder:font-light hover:border-black transition ease-in-out'>
                <option className='text-black' value="I have one">{t("form.question5.answer1")}</option>
                <option className='text-black' value="It&apos;s a personal request">{t("form.question4.answer2")}</option>
            </select>
        </div>

        <div className='flex flex-col gap-y-4'>
            <p>6. {t("form.question6.question")}</p>
            <input type="email" id="email" name="email" placeholder={t("form.question6.answer1")} className='h-[75px] bg-transparent border-b outline-none placeholder:font-light hover:border-black transition ease-in-out' required/>
            <input type="text" id="tel" name="telephone" placeholder={t("form.question6.answer2")} className='h-[75px] bg-transparent border-b outline-none placeholder:font-light hover:border-black transition ease-in-out' required/>
        </div>

        <div className='flex flex-col gap-y-4'>
            <p>7. {t("form.question7.question")}</p>
            <textarea id="message" name="message" placeholder={t("form.question7.option")} className='h-[75px] bg-transparent border-b outline-none placeholder:font-light hover:border-black transition ease-in-out'></textarea>
        </div>

        <div className='w-full flex justify-end'>
            <button
                className={`
                    relative w-fit z-0 flex items-center gap-2 overflow-hidden  border-[1px] 
                    border-[#FFA41B] px-6 py-4 font-[Oswald] font-light
                    uppercase text-[#FFA41B] transition-all duration-500 md:text-2xl text-4xl
                    
                    before:absolute before:inset-0
                    before:-z-10 before:translate-x-[150%]
                    before:translate-y-[150%] before:scale-[2.5]
                    before:rounded-[100%] before:bg-[#FFA41B]
                    before:transition-transform before:duration-1000
                    before:content-[""]

                    hover:scale-105 hover:text-white
                    hover:before:translate-x-[0%]
                    hover:before:translate-y-[0%]
                    active:scale-95`}
            >
                <span>{t("form.send")}</span>
            </button>
        </div>
    </form>
  );
}

export default Form;
